.loading-fullscreen {
    display: none;
    position: fixed;
    z-index: 1030;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.show {
        display: block;
    }
}

.loading-overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .75);
}

.loading-elements {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-icon {
    animation: loading-spin 2s infinite ease-in-out;
}

@keyframes loading-spin {
    0%      { transform: scale(1) rotateY(0deg); }
    50%     { transform: scale(1.1) rotateY(180deg); }
    100%    { transform: scale(1) rotateY(0deg); }
}