@import 'assets/scss/variables.scss';

.page-auth {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 6rem;
    padding-bottom: 6rem;
    color: #000;
}

.auth-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 64px;
    overflow: hidden;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    margin-bottom: 6rem;
}

.auth-form {
    color: #000;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 414px;
    border: none;
    border-radius: .5rem;
    background-color: var(--bs-white);

    &.register-form {
        max-width: unset;
    }

    .card-header {
        font-size: 1.125rem;
        padding: 1.5rem;
    }

    .card-body {
        padding: 1.5rem;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-footer {
        padding: 1rem 1.5rem;
    }

    .form-control {
        color: #000;
    }
}

.btn-social-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: .75rem 1rem;

    &.btn-login-google {
        color: #000;
        border-color: var(--bs-gray-200);
    }

    img {
        width: 24px;
        height: 24px;
        margin-right: .5rem;
    }

    span {
        flex-grow: 1;
    }
}