// Bootstrap Override
$theme-colors: (
    "primary": #2377FC,
    "secondary": #2F3137,
    "success": #4DC87E,
    "info": #F95192,
    "warning": #ff914d,
    "danger": #F20000,
    "light": #F6F6F6,
    "dark": #000000,
);

$font-family-sans-serif: 'Plus Jakarta Sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $font-size-base: .875rem;
$font-weight-base: 300;
$body-color: #2F3137;
$primary: #2377FC;

// Form Control
$input-placeholder-color: rgba(255, 255, 255, .5);

$input-btn-padding-y: .625rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: .375rem;
$input-btn-padding-x-sm: .5rem;

$input-btn-font-size-lg: 1.125rem;
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1.5rem;

$input-bg: rgba(255, 255, 255, .12);
$input-border-color: rgba(255, 255, 255, .5);
$input-border-radius: 0;

$form-label-font-weight: 500;

// $accordion-color: #000;
// $accordion-button-color: $accordion-color;