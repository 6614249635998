.page-contact-us {
    .page-header {
        padding-top: 40vh !important;
    }
}

.contact-info-item {
    padding: 1.25rem 1.5rem 2rem;
    background-color: rgba(255, 255, 255, .12);
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 1rem;
}

.send-message-section {
    .col-md-5 {
        position: relative;
    }
}