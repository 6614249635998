@import 'assets/scss/variables.scss';

.home-header {
    padding-top: 16rem;

    .container-xl {
        position: relative;
        z-index: 2;
    }

    .section-bg {
        background-position: bottom center;
    }

    .sec-header {
        position: relative;

        .sec-title {
            font-size: 2.5rem;
            font-weight: 800;

            .text-primary {
                font-size: 4.5rem;
            }
        }

        .sec-description {
            font-size: 1.5rem;
        }
    }

    .header-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .75rem;
    }

    .header-features {
        position: relative;
        color: #FFF;
        padding: 1rem;
        margin-top: 200px;

        .header-features-bg {
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: var(--bs-secondary);
        }

        .header-feature-item {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .header-feature-line {
            flex-shrink: 0;
            width: 8px;
            height: 100%;
            min-height: 80px;
            background-color: var(--bs-primary);
            border-radius: 4px;
            margin-right: 1.5rem;
        }

        p {
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0;
        }
    }

    @media (min-width: 768px) {
        .sec-header {
            .sec-description {
                margin-left: 0;
            }
        }
    }

    @media (max-width: 991px) {
        padding-top: 8rem;
        padding-bottom: 3rem;

        .sec-header {
            .sec-title {
                font-size: 2.25rem;
                margin-bottom: 1rem;
            }
    
            .sec-description {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 767px) {
        .sec-header {
            .sec-title {
                font-size: 2rem;

                .text-primary {
                    font-size: 2.5rem;
                }
            }
        }

        .header-features {
            .header-feature-line {
                width: 6px;
                margin-right: .75rem;
            }
        }
    }
}

.section-tag {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;

    .section-tag-text {
        display: inline-block;
        padding: .75rem 1rem;
        min-width: 320px;
        font-weight: 700;
        color: #000;
        background-color: #FFF;
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
    }
}

.solution-section {
    padding-top: 15rem;
    padding-bottom: 12rem;
    color: #FFF;

    .section-bg {
        &.video-bg {
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &.tiles-bg {
            background-color: rgba($color: var(--bs-secondary-rgb), $alpha: 0.85);
        }
    }

    .sec-header {
        .sec-title {
            font-size: 1.75rem;
            font-weight: 700;
            line-height: 1.4;
        }
    }

    @media (max-width: 767px) {
        padding-top: 8rem;
        padding-bottom: 6rem;

        .sec-header {
            .sec-title {
                font-size: 1.25rem;
            }
        }
    }
}

.features-section {
    .section-bg {
        &.desktop {
            right: calc(60% + 3rem);
            background-position: center;
            background-size: cover;
        }

        &.mobile {
            height: 300px;
        }
    }

    .feature-item {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .feature-item-number {
            font-size: 2rem;
            font-weight: 800;
            color: var(--bs-primary);
            margin: 0;
        }

        .feature-item-content {
            h3 {
                font-size: 1.5rem;
                margin-bottom: .75rem;
            }

            p {
                margin: 0;
            }
        }
    }

    @media (max-width: 767px) {
        padding-top: calc(4rem + 300px);
    }
}

.features_online-section {
    color: #FFF;
    background-color: #01060e;

    .section-bg {
        z-index: 0;

        &.desktop {
            left: calc(60% + 3rem);
            background-position: center;
            background-size: cover;
        }

        &.mobile {
            height: 300px;
        }
    }

    .feature-item {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .feature-item-number {
            font-size: 2rem;
            font-weight: 800;
            color: var(--bs-primary);
            margin: 0;
        }

        .feature-item-content {
            h3 {
                font-size: 1.5rem;
                margin-bottom: .75rem;
            }

            p {
                margin: 0;
            }
        }
    }

    @media (max-width: 767px) {
        padding-top: calc(4rem + 300px);
    }
}

.why-section {
    padding-top: 10rem;
    color: #FFF;

    .why-item {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .why-item-content {
            h3 {
                font-size: 1.5rem;
                margin-bottom: .75rem;
            }

            p {
                margin: 0;
            }
        }
    }
}

.benefits-section {
    color: #FFF;
    background-color: var(--bs-secondary);

    .benefit-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        text-align: center;

        .benefit-item-icon {
            width: 60px;
            height: 60px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .benefit-item-content {
            h3 {
                font-size: 1.5rem;
                margin-bottom: .75rem;
            }

            p {
                font-weight: 300;
                max-width: 70%;
                margin: 0 auto;
            }
        }
    }
}

.targets-section {
    padding-top: 10rem;
    color: #FFF;

    .target-item {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .target-item-content {
            h3 {
                font-size: 1.5rem;
                margin-bottom: .75rem;
            }

            p {
                margin: 0;
            }
        }
    }
}

.contact-section {
    color: #FFF;
    background-color: var(--bs-dark);

    .sec-header {
        .sec-title {
            position: relative;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.4;
            margin-top: 74px;
            margin-bottom: 3rem;

            span {
                position: relative;
                z-index: 1;
            }

            img {
                position: absolute;
                z-index: 0;
                top: -96px;
                left: -72px;
                opacity: .5;

                @media (max-width: 767px) {
                    left: -24px;
                }
            }
        }
    }
}

.introduction-section {
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: -250px;
        left: -200px;
        width: 640px;
        height: 640px;
        border-radius: 50%;
        background: linear-gradient(37deg, rgba(0, 255, 255, 0.60) 14.29%, rgba(85, 0, 255, 0.60) 85.71%);
        filter: blur(132px);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -100px;
        right: -300px;
        width: 640px;
        height: 640px;
        border-radius: 50%;
        background: linear-gradient(37deg, rgba(0, 255, 255, 0.60) 14.29%, rgba(85, 0, 255, 0.60) 85.71%);
        filter: blur(132px);
    }

    .container-xl {
        position: relative;
        z-index: 2;
    }

    .bottom-left {
        right: 65%;
        top: 60%;
        bottom: -30%;
        background-position: top right;
        background-size: auto 100%;
    }
    .video-frame {
        border: 6px solid #919191;
        border-radius: 0 0 1rem 1rem;
        overflow: hidden;
    }
}

.benefits-section {
    .benefits-list {
        list-style: none;
        padding-inline-start: 0;

        li {
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--bs-primary);
            }
        }
    }
}

.howtoplay-section {
    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(180deg, #18181B 0%, rgba(24, 24, 27, 0.00) 90.38%);
    }

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(0deg, #18181B 0%, rgba(24, 24, 27, 0.00) 90.38%);
    }

    .container-xl {
        position: relative;
        z-index: 2;
    }

    .section-bg {
        &.bg {
            border-radius: 50%;
            background: radial-gradient(44.72% 44.72% at 50% 50%, rgba(255, 238, 82, 0.30) 0%, rgba(255, 238, 82, 0.00) 100%);
        }

        &.slider-ellipse {
            bottom: -6rem;
            background-position: bottom center;
            background-size: contain;
        }
    }

    .guide-slider {
        .swiper-wrapper {
            align-items: center;
            height: 630px;
        }

        .guide-slide-item {
        }
        .guide-slide-img {
            height: 450px;
            width: auto !important;
            transition: height .15s ease;
        }

        .swiper-slide-active {
            .guide-slide-item {
            }
            .guide-slide-img {
                height: 630px;
            }
        }
    }

    .step-info {
        .step-title {
            font-size: 2.25rem;
            font-weight: 700;
        }

        .step-description {
            margin: 0 auto;
            max-width: 520px;
        }
    }

    @media (max-width: 767px) {
        padding-top: 0;

        .section-bg {
            &.slider-ellipse {
                background-size: contain;
            }
        }
        .guide-slider {
            margin-left: -.75rem;
            margin-right: -.75rem;

            .swiper-wrapper {
                height: 400px;
            }

            .guide-slide-img {
                height: 300px;
            }
    
            .swiper-slide-active {
                .guide-slide-img {
                    height: 400px;
                }
            }
        }
        
    }
}

.rewards-section {
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: -250px;
        left: -200px;
        width: 640px;
        height: 640px;
        border-radius: 50%;
        background: linear-gradient(37deg, rgba(0, 255, 255, 0.60) 14.29%, rgba(85, 0, 255, 0.60) 85.71%);
        filter: blur(132px);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -100px;
        right: -300px;
        width: 640px;
        height: 640px;
        border-radius: 50%;
        background: linear-gradient(37deg, rgba(0, 255, 255, 0.60) 14.29%, rgba(85, 0, 255, 0.60) 85.71%);
        filter: blur(132px);
    }

    .container-xl {
        position: relative;
        z-index: 2;

        @media (min-width: 768px) {
            padding-top: 4rem;
            padding-bottom: 6rem;            
        }
    }

    .section-bg {
        background-position: center right;
        background-size: contain;
    }

    .reward-milestones {
        margin: 2rem 0;

        .milestone-item {
            display: flex;
            flex-direction: column;
            padding: .75rem;
            border-radius: 1.5rem;
            border: 1px solid rgba(148, 147, 172, 0.32);
            background: rgba(255, 255, 255, 0.10);

            h3 {
                font-size: 1.25rem;
                font-weight: 400;
                margin-bottom: .75rem;
            }

            .milestone-item-rewards {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 1.5rem;
                    font-weight: 700;
                    margin: 0;
                    margin-left: 1rem;
                }
            }
        }
    }
}

.howtoboost-section {
    .section-bg {
        transform: rotate(25.629deg);
        flex-shrink: 0;
        border-radius: 108.65594rem;
        background: radial-gradient(52.62% 52.06% at 28.67% 77.98%, rgba(255, 238, 82, 0.25) 0%, rgba(24, 24, 24, 0.50) 100%);
        filter: blur(400px);
    }

    .boost-package {
        border-radius: 1.5rem;
        border: 1px solid rgba(148, 147, 172, 0.32);
        background: rgba(255, 255, 255, 0.10);
        height: 100%;

        .package-content {
            margin-top: -4rem;
        }

        .package-header {
            padding: 1rem;

            h3 {
                font-size: 3rem;
            }

            p {
                font-size: 1.5rem;
            }
        }

        .package-benefits {
            ul {
                list-style: none;
                padding-inline-start: 0;
                margin: 0;

                li {
                    position: relative;
                    padding: 1rem;
                    padding-left: 48px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 1rem;
                        width: 24px;
                        height: 24px;
                        background-image: url('../../../assets/images/tick.png');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &:not(:last-child) {
                        border-bottom: 0.5px solid rgba(148, 147, 172, 0.32);
                    }
                }
            }
        }
    }
}

.social-section {
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: -200px;
        right: -200px;
        width: 480px;
        height: 480px;
        border-radius: 50%;
        background: linear-gradient(37deg, rgba(0, 255, 255, 0.50) 14.29%, rgba(85, 0, 255, 0.50) 85.71%);
        filter: blur(132px);
    }

    .section-bg {
        background-size: contain;
        z-index: 2;
    }

    .container-xl {
        position: relative;
        z-index: 3;
    }

    .social-button-up {
        position: relative;
        z-index: 2;
        text-align: center;
        height: 150px;

        .section-bg {
            top: 40px;
            left: -10%;
            right: -10%;
            background-position: top center;
        }

        .btn-header-down {
            background: var(--gradient, linear-gradient(90deg, #0094FF 0%, #4DC87E 100%));
            border: none !important;

            img {
                transform: rotate(180deg);
            }

            &:hover, &.active {
                img {
                    filter: none;
                }
            }
        }
    }

    .navbar-social-link {
        img {
            width: 96px;
            height: 96px;
        }
    }

    .navbar-social-page {
        .nav-link {
            font-weight: 600;
            color: #FFF;
        }
    }

    @media (max-width: 767px) {
        .navbar-social-link {
            img {
                width: 64px;
                height: 64px;
            }
        }
    }
}